import { ConstantPool } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalAlertService } from "src/app/global-alert.service";
import { GlobalService } from "src/app/global.service";
import * as moment from "moment";
import Swal from "sweetalert2";

@Component({
  selector: "app-historico-datos",
  templateUrl: "./historico-datos.component.html",
  styleUrls: ["./historico-datos.component.css"],
})
export class HistoricoDatosComponent implements OnInit {
  constructor(
    public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService
  ) {
    this.global.initData();
    const vari = localStorage.getItem("USERDATALIWA");
    this.USERDATALIWA = vari.split(",");
    // console.log('USERDATALIWA', this.USERDATALIWA);
    this.global.UserName = this.USERDATALIWA[3];
    this.global.Id_Usuario = this.USERDATALIWA[0];
  }
  // CUERPO DE LA QUERY
  idTicket;
  Evidence: boolean = false;
  descriptionBenefit;
  typePQRS = "prueba";
  id_Proyecto;
  NombreProyecto: String;
  NombreSeccional: String;
  id_Seccional;
  id_Beneficiario;
  provBenefit;
  // CUERPO DE LA QUERY
  datosBeneficiario;
  USERDATALIWA;
  valorCanal;
  valorMenu1;
  valorSubMenu1: String = '-';
  valorSubMenu2: String = '-';
  clientClass;
  codigo;

  banderaCrearTicket: boolean = false;
  canales = [
    { type: "WhatsApp" },
    { type: "Correo" },
    { type: "Linea de Atencion" },
    { type: "Oficina" },
    { type: "Chatbot" },
    { type: "Pagina Web" },
  ];
  types = [
    { type: "Novedad Solucion" },
    { type: "Pqrs" },
    { type: "Facturacion" },
  ];
  //  SUB MENUS 1 APARTIR DEL PRIMER CASO
  opcionPqrs: boolean = false;
  typesSubMenu1Pqrs = [
    { type: "Abandono de Vivienda" },
    { type: "Cambio de Titularidad" },
    { type: "Desistimiento" },
    { type: "Traslado" },
  ];
  opcionNovedad: boolean = false;
  typesSubMenu1Novedad = [
    { type: "Daño" }, 
    { type: "Sin SISFV Instalada" }, 
    { type: "Garantia" }, 
    { type: "Reposicion" }, 
    { type: "Hurto Solucion" }];  
  opcionFacturacion: boolean = false;
  typesSubMenu1Facturacion = [
    { type: "Factura No Entregada" },
    { type: "Inconformidad Con Facturacion"},
    { type: "Envío de Factura y/o Comprobante"},
  ];
  opcionSubMenu2Danos: boolean = false;
  //typesSubMenu2Danos = [
  //  { type: "% Carga Bateria" },
  //  { type: "Apertura gabinete" },
  //  { type: "Datos Tension" },
  //  { type: "Gestor" },
  //  { type: "otro" },
  //  { type: "Panel con daño fisico" },
  //  { type: "sin servicio" },
  //  { type: "Tarjeta datos" },
  //  { type: "Tecnico (CRM)" }
  //];
  opcionSubMenu2SISFV: boolean = false;
  opcionSubMenu2Garantia: boolean = false;
  opcionSubMenu2Reposicion: boolean = false;
  opcionSubMenu2Hurto: boolean = false;
  opcionSubMenu2Abandono: boolean = false;
  opcionSubMenu2Cambio: boolean = false;
  opcionSubMenu2Desistimiento: boolean = false;
  opcionSubMenu2Traslado: boolean = false;

  opcionSubMenu2Entrega: boolean = false;
  opcionSubMenu2Inconformidad: boolean = false;

  //  SUB MENUS 3 APARTIR DEL tercer CASO
  //opcionSubMenu3TipoTramite: boolean = false;
  //typesSubMenu3TipoTramite = [{ type: "Facturacion" }, { type: "Prestacion" }];
  
  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.datosBeneficiario = this.global.informacionBeneficiario
      ? this.global.informacionBeneficiario
      : JSON.parse(window.localStorage.getItem("informacion beneficiario"));
    console.log(this.datosBeneficiario, 'echiohhcohcohcohcohoo')
    if (this.global.banderaModificaionTicket) {
      this.spinner.show()
      // this.banderaCrearTicket = true
      // console.log(this.global.Id_Ticket.length > 2 ,'yteuietyueteyuteut')
      // const pdata2 = { option: "insertar2", texto: `select * from db_liwa.Tickets_New where Id_Tickets = '${this.global.Id_Ticket}';` };
      //         this.global.consultar(pdata2, (err2, response2) => {
      //           this.valorMenu1 = response2[0][6]
      //           this.handleChangeSubMenu1()
      //           this.valorSubMenu1 = response2[0][7]
      //           this.handleChangeSubMenu2()
      //           this.valorSubMenu2 = response2[0][8]
      //           this.handleChangeSubMenu3()
      //           this.valorSubMenu3 = response2[0][9]
      //           this.handleChangeDescriction()
      //           this.descriptionBenefit = response2[0][10]
      //           this.id_Seccional = response2[0][3]
      //           this.id_Proyecto = response2[0][2]
      //           this.id_Beneficiario = response2[0][5]
      //           console.log('eeses2255',this.id_Proyecto,this.id_Seccional)
      //           const pdata9 = { option: 'insertar2', texto: `SELECT Nombre,(SELECT Nombre FROM db_liwa.Seccionales WHERE id_Seccional = ${this.id_Seccional}) FROM db_liwa.tipoProyecto WHERE id_tipoProyecto = ${this.id_Proyecto};` };
      //           this.global.consultar(pdata9,async (err9, response9) => {
      //             this.NombreProyecto = await response9[0][0];
      //             this.NombreSeccional = await response9[0][1];
      //             console.log('eeses22',this.NombreProyecto,
      //             this.NombreSeccional,)
      //           })
      this.spinner.hide()
      // })

    }
    console.log('eeses2255', this.datosBeneficiario[0][3], this.datosBeneficiario[0][4])
    const pdata9 = { option: 'insertar2', texto: `SELECT Nombre,(SELECT Nombre FROM db_liwa.Seccionales WHERE id_Seccional = ${this.datosBeneficiario[0][4]}) FROM db_liwa.tipoProyecto WHERE id_tipoProyecto = ${this.datosBeneficiario[0][3]};` };
    this.global.consultar(pdata9, async (err9, response9) => {
      this.NombreProyecto = await response9[0][0];
      this.NombreSeccional = await response9[0][1];
      console.log('eeses33', this.NombreProyecto,
        this.NombreSeccional,)
    })
  }
  handleChangeSubMenu1() {
    //  console.log('mis vales',this.valorMenu1 )

    this.banderaCrearTicket = false;

    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2SISFV = false;
    this.opcionSubMenu2Garantia = false;
    this.opcionSubMenu2Reposicion = false;
    this.opcionSubMenu2Hurto = false;

    this.opcionSubMenu2Abandono = false;
    this.opcionSubMenu2Cambio = false;
    this.opcionSubMenu2Desistimiento = false;
    this.opcionSubMenu2Traslado = false;

    this.opcionSubMenu2Entrega = false;
    this.opcionSubMenu2Inconformidad = false;
    this.valorSubMenu1 = "-";
    this.valorSubMenu2 = "-";

    switch (this.valorMenu1) {
      case "Novedad Solucion":
        this.opcionNovedad = true;
        this.opcionPqrs = false;
        this.opcionFacturacion = false;
        break;
      case "Pqrs":
        this.opcionPqrs = true;
        this.opcionNovedad = false;
        this.opcionFacturacion = false;
        break;
      case "Facturacion":
        this.opcionFacturacion = true;
        this.opcionPqrs = false;
        this.opcionNovedad = false;
        break;
      default:
        this.opcionFacturacion = false;
        this.opcionNovedad = false;
        this.opcionPqrs = false;

        break;
    }
  }
  handleChangeSubMenu2() {
    console.log('mis vales 2 ', this.valorSubMenu1)
    this.banderaCrearTicket = false;
    this.valorSubMenu2 = "";

    this.opcionSubMenu2Danos = false;
    this.opcionSubMenu2SISFV = false;
    this.opcionSubMenu2Garantia = false;
    this.opcionSubMenu2Reposicion = false;
    this.opcionSubMenu2Hurto = false;
    this.opcionSubMenu2Abandono = false;
    this.opcionSubMenu2Cambio = false;
    this.opcionSubMenu2Desistimiento = false;
    this.opcionSubMenu2Traslado = false;
    this.opcionSubMenu2Entrega = false;
    this.opcionSubMenu2Inconformidad = false;

    switch (this.valorSubMenu1) {
      case "Daño":
        this.opcionSubMenu2Danos = true;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Sin SISFV Instalada":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = true;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Garantia":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = true;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Reposicion":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = true;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Hurto Solucion":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = true;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Abandono de Vivienda":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = true;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Cambio de Titularidad":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = true;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Desistimiento":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = true;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Traslado":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = true;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Factura No Entregada":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = true;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
      case "Inconformidad Con Facturacion":
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = true;
        this.banderaCrearTicket = true;
        break;
      default:
        this.opcionSubMenu2Danos = false;
        this.opcionSubMenu2SISFV = false;
        this.opcionSubMenu2Garantia = false;
        this.opcionSubMenu2Reposicion = false;
        this.opcionSubMenu2Hurto = false;
        this.opcionSubMenu2Abandono = false;
        this.opcionSubMenu2Cambio = false;
        this.opcionSubMenu2Desistimiento = false;
        this.opcionSubMenu2Traslado = false;
        this.opcionSubMenu2Entrega = false;
        this.opcionSubMenu2Inconformidad = false;
        this.banderaCrearTicket = true;
        break;
    }
  }
  handleChangeDescriction() {
    this.valorSubMenu2 ? (this.banderaCrearTicket = true) : console.log();
  }
  atras() {
    if (this.global.banderaModificaionTicket) {
      this.global.banderaModificaionTicket = false;
      this.ruta.navigateByUrl("/user-ticket");
    } else {
      window.localStorage.setItem("informacion beneficiario", JSON.stringify([]));
      this.ruta.navigateByUrl("/crear-ticket");
    }
  }
  crear() {
    this.spinner.show();
    const exMoment = this.global.Id_Usuario + "_" + moment.now();
    // console.log(this.global.Id_Ticket.length > 2 , 'porque llega id vacio??');
    this.idTicket = this.global.Id_Ticket ? this.global.Id_Ticket : exMoment;
    if (this.Evidence == false) {
      try {
        // console.log(this.idTicket);
        setTimeout(() => {
          const idStatuses = this.valorSubMenu2 == "Agendamiento visita" ? 8 :
            this.valorMenu1 === "llamada outbound" ? 11 : 1;
          if (this.typePQRS === undefined) {
            // console.log(this.typePQRS, 'etettetetetetetetety')
            this.alerta.descErr("Revise por favor que no hay campos vacíos");
            this.spinner.hide();
          } else {
            this.id_Proyecto = this.global.banderaModificaionTicket ? this.id_Proyecto : this.datosBeneficiario[0][3]
            this.id_Seccional = this.global.banderaModificaionTicket ? this.id_Seccional : this.datosBeneficiario[0][4]
            this.id_Beneficiario = this.global.banderaModificaionTicket ? this.id_Beneficiario : this.datosBeneficiario[0][1]
            //  console.log(this.datosBeneficiario,'kelly pero que monda')
            console.log(idStatuses, 'este es el que acabo de poner');
            const query = "REPLACE INTO db_liwa.Tickets_New (Id_Tickets,Id_Usuario,Id_Proyecto,Id_Seccional,Id_Statuses,Id_Beneficiario,Menu,SubMenu1,SubMenu2,SubMenu3,Description,Creation_Date,Encargado,IsDelete,IsActive,IsArchived,Codigo,canal)" +
              " VALUES ('" +
              this.idTicket +
              "','" +
              this.global.Id_Usuario +
              "','" +
              this.id_Proyecto +
              "','" +
              this.id_Seccional +
              "','" +
              idStatuses +
              "','" +
              this.id_Beneficiario +
              "','" +
              this.valorMenu1 +
              "','" +
              this.valorSubMenu1 +
              "','" +
              '-' + 
              "','" +
              '-' +
              "','" +
              this.descriptionBenefit +
              "', '" +
              // this.typePQRS + '\',\'' +
              moment().format("YYYY-MM-DD HH:mm:ss") +
              "','" +
              "-" +
              "','" +
              0 +
              "','" +
              1 +
              "','" +
              0 +
              "','" +
              this.codigo +
              "','" +
              this.valorCanal +
              "');";
            const pdata1 = { option: "insertar3", texto: query };
            console.log(query, ' este es la queryrota??')
            console.log(pdata1, "este es pdata1");
            this.global.consultar(pdata1, (err, response) => {
              console.log('este es el ganador', response)
              if (response == true) {
                let idStatusesUpdate = this.valorMenu1 === "llamada outbound" ? 1 : idStatuses
                console.log('entro', idStatusesUpdate)
                // alertFunctions.UserSuccess();
                const query2 =
                  "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)" +
                  " VALUES ('" +
                  this.idTicket +
                  "','" +
                  "PQRS Creada" +
                  "','" +
                  moment().format("YYYY-MM-DD HH:mm:ss") +
                  "','" +
                  idStatusesUpdate +
                  "','" +
                  0 +
                  "','" +
                  1 +
                  "');";
                const pdata2 = { option: "insertar3", texto: query2 };
                this.global.consultar(pdata2, (err2, response2) => {
                  console.log(response2, 'la concha de tu hermana');
                  if (err2 == null && response2 == true) {
                    this.valorMenu1 != "llamada outbound" ? this.eviarSMS() : console.log('llamada outbound por eso no se envia mensaje');
                    // esta era las unicas opciones que permitiamos antes
                    // if((this.valorSubMenu3 == "Agendamiento visita" || this.valorSubMenu2 == "Agendamiento visita") && this.global.idRole!=15){
                    if ((this.valorMenu1 != "Novedad Solucion") && this.global.idRole != 15) {
                      this.spinner.hide()
                      console.log('eeses', this.NombreProyecto,
                        this.NombreSeccional,)
                      Swal.fire({
                        title: '<strong>¿Desea Agendar una visita"?</strong>',
                        icon: "question",
                        showCloseButton: true,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: "#093579",
                        cancelButtonText: "No, Gracias!!",
                        cancelButtonAriaLabel: "Thumbs up, great!",
                        confirmButtonText: "Si, estoy seguro",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setTimeout(() => {
                            let data = JSON.parse(
                              window.localStorage.getItem(
                                "informacion beneficiario"
                              )
                            );
                            this.global.withoutTicket = false;
                            this.global.Id_Ticket = this.idTicket;
                            this.global.selectedForVisit = [
                              this.valorMenu1,
                              this.NombreProyecto,
                              this.NombreSeccional,
                              data[0][4],
                              data[0][8],
                              this.descriptionBenefit,
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                            ];
                            this.spinner.hide();
                            this.alerta.descValid(
                              "Nueva PQRS creada con éxito"
                            );
                            this.ruta.navigateByUrl("/crear-visita");
                          }, 1000);
                        } else {
                          this.ruta.navigateByUrl("/user-ticket");
                        }
                      });
                    } else {
                      setTimeout(() => {
                        this.spinner.hide();
                        this.alerta.descValid("Nueva PQRS creada con éxito");
                        this.global.banderaModificaionTicket = false;
                        this.global.Id_Ticket = '-'
                        this.ruta.navigateByUrl("/user-ticket");
                      }, 1000);
                    }
                  } else {
                    this.alerta.descErr(
                      "Revise por favor que no hay campos vacíos"
                    );
                    console.log(err, response, "que pasara 33");
                    this.spinner.hide();
                  }
                });
              } else {
                this.alerta.descErr(
                  "Revise por favor que no hay campos vacíos"
                );
                this.spinner.hide();
                console.log(err, response, "que pasara");
              }
            });
          }
        }, 400);

      } catch (error) {
        // console.log('error:', +error);
      }
    } else {
      // this.loadPhoto(this.idTicket);
    }
  }

  crearUnComentario() {
    const pdataComentario = {
      option: 'insertar3',
      texto: `INSERT INTO db_liwa.Comentarios_Tickets (Comentario, Id_Ticket, Id_Usuario)
         VALUES('${this.descriptionBenefit}', '${this.global.Id_Ticket}', '${this.global.Id_Usuario}');`
    }
    this.global.consultar(pdataComentario, (err2, response2) => {
      console.log(response2)
      if (response2 == true) {
        // alertFunctions.UserSuccess();
        const query2 =
          "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)" +
          " VALUES ('" +
          this.global.Id_Ticket +
          "','" +
          "Comentario creado por llamada del cliente" +
          "','" +
          moment().format("YYYY-MM-DD HH:mm:ss") +
          "','" +
          13 +
          "','" +
          0 +
          "','" +
          1 +
          "');";
        const pdata2 = { option: "insertar3", texto: query2 };
        this.global.consultar(pdata2, (err2, response2) => {
          this.spinner.hide();
          this.alerta.descValid("Nueva Comentario creada con éxito");
          this.global.banderaModificaionTicket = false;
          this.global.Id_Ticket = '-'
          this.ruta.navigateByUrl("/user-ticket");
        })
      }
    })
  }
  //  BL            A ESTA PETICION HAY QUE ADACTARLA A LA MIGRACION QUE SE LE HISO A LA BASE DE DATOS YA QUE LOS PARAMETROS HAN CAMBIADO
  // enviarcorreo() {
  //   setTimeout(() => {
  //     const pdata9 = {
  //       option: "mail",
  //       ticket: this.idTicket,
  //       Seccional: this.datosBeneficiario[0][64],
  //       descr: this.descriptionBenefit,
  //       type: this.valorMenu1,
  //       contacto:  this.global.UserName,
  //       tel: this.phoneBenefit,
  //       user: this.datosBeneficiario[0][11],
  //       municipio: this.provBenefit,
  //     };
  //     this.global.consultar(pdata9, (err2, response2) => {
  //       // console.log('Datos correo', response2);
  //     });

  //   }, 500);
  // }
  async eviarSMS() {
    const pdata1 = {
      option: "insertar2",
      texto: `SELECT T.Id_Proyecto, T.Id_Seccional,T.Id_Beneficiario,
        (SELECT M.Id_Maintenance FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT M.Id_Technical FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT M.Status FROM db_liwa.Maintenance_new M WHERE M.Id_Ticket = T.Id_Tickets),
        (SELECT S.Name_Status FROM db_liwa.Ticket_Statuses S WHERE S.Id_Statuses = T.Id_Statuses),
        (SELECT B.Telefono FROM db_liwa.AOM B WHERE B.Id = T.Id_Beneficiario)
         FROM db_liwa.Tickets_New T WHERE T.Id_Tickets = '${this.idTicket}';`,
    };
    this.global.consultar(pdata1, (err9, response9) => {
      const pdata9 = {
        'number': '57' + this.datosBeneficiario[0][9],
        'message': `DISPOWER ESP le informa PQR radicada No. ${this.idTicket}, Fecha maxima de respuesta en (15 Dias habiles )`,
        'type': '1'
      };
      //const pdata8 = {
      //  'number': '57' + "3164946552",
      //  'message': `DISPOWER ESP le informa PQR radicada No. ${this.idTicket}, Fecha maxima de respuesta en (15 Dias habiles )`,
      //  'type': '1'
      //};
      //const pdata7 = {
      //  'number': '57' + "3153583711",
      //  'message': `DISPOWER ESP le informa PQR radicada No. ${this.idTicket}, Fecha maxima de respuesta en (15 Dias habiles )`,
      //  'type': '1'
      //};
      this.global.consultarSMS(pdata9, (err2, response2) => {
        //this.global.consultarSMS(pdata8, (err2, response2) => {
        //  this.global.consultarSMS(pdata7, (err2, response2) => {
            let query = `REPLACE INTO db_liwa.MensajesAOM (Id_Proyecto,Id_Seccional,Id_Beneficiario,Id_Maintenance,Id_Ticket,Id_User,Id_Tecnico,Numero_Dest,Tipo_Mantenimiento,Tipo_Ticket,ContenidoSMS)
            VALUES ('${response9[0][0]}','${response9[0][1]}','${response9[0][2]}','${response9[0][3]}','${this.idTicket}','${this.global.Id_Usuario}','${response9[0][4]}','${pdata9.number}','${response9[0][5]}','${response9[0][6]}','${pdata9.message}');`;
            const pdata5 = { option: "insertar3", texto: query }
            this.global.consultar(pdata5, (err2, response2) => {
            });
        //  })
        //})
      });
    });
  }
}
